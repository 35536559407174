
/* #region Universal */

*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.example {
  -ms-overflow-style: none;
}

body {
  margin: 0;
  min-height:'100%';
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-margin-before: 0em;
  min-height: -webkit-fill-available;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}

html, body, #app {
  max-width: 100%;
  min-height: -webkit-fill-available;
}

svg:hover{
  cursor: pointer !important;
  transform: scale(1.2);
}

.watchlistText:hover{
  cursor: pointer;
  text-decoration: underline;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 0; 
  background-color: #FFF;
  color: #000;
}

input	{
  color: 'white'
}

.golden {
  color:'#deb723' !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #28ABDD !important;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #28ABDD60 !important;
}

.pointerHover:hover{
  cursor: pointer !important;
}

.tooltip {
  pointer-events: none;
}

/* #endregion Universal */

/* #region Dashboard */

.countdownTimer{
  display:table;
  margin-left: auto;
  margin-right:auto;
  color:lightgray;
  font-size:25px;
}

.main-card-outer{
  margin-bottom: unset !important;
  min-height:100vh !important;
}
.fixed-size-list{
  /* height: 100vh !important; */
  height: 93vh !important;
  overflow: scroll !important;
  padding-bottom:60px !important;
}

.full-fixed-size-list{
  /* height: 100vh !important; */
  height: 60vh !important;
  overflow: scroll !important;
  padding-bottom:30px !important;
}

.below-nav-block{
  background-color: #141B2B !important;
  max-height:100vh
}
.allMiddleContent{
  max-height: 98vh;
  overflow: hidden;
}

.main-data-row{
  width: unset !important;
}

.expand-text{
  color: 'white' !important;
  font-size:12px !important;
}

.expand-text-title{
  color: 'white' !important;
  font-size:14px !important;
  font-weight: 'bold' !important;
}

.MuiFormHelperText-root, .MuiInputBase-input, .MuiInput-input, .MuiInputBase-inputAdornedStart, .MuiInputBase-inputAdornedEn{
  color: 'white'
}

.dashboardDatePicker{
  margin-top: 8px !important;
  margin-bottom:unset !important;
}

.news-block{
  overflow-y: scroll !important;
}

.card-stats{
  margin-bottom:10px !important;
}

.maxed-sweepscore .progress-bar {
  background-color: #28ABDD;
}
.normal-sweepscore .progress-bar {
  background-color: #4E5CBF;
}
.bg-secondary {
  background-color: unset!important;
}

.callBadge {
  background-color: #0CD17D !important;
  border-radius: 5px;
}
.putBadge {
  background-color: #F87240 !important;
  border-radius: 5px;
}

.sweepBadge{
  background-color: #28ABDD !important;
  border-radius: 5px;
}
.tradeBadge{
  background-color: #685AD2 !important;
  border-radius: 5px;
}

.neutralBadge{
  background-color: #838383 !important;
  border-radius: 5px;
}
.bullBadge{
  background-color: #0CD17D !important;
  border-radius: 5px;
}
.bearBadge{
  background-color: #F87240 !important;
  border-radius: 5px;
}

.viewChart{
  cursor: pointer;
}
.viewChart:hover {
  text-decoration: underline;
}

.header-container > .th{
  text-align: center !important;
}
.header-container .th select{
  width: 83px;
  height: 38px;
  border: 1px solid #9A9A9A;
  border-radius: 7px;
  opacity: 1;
  margin: auto auto 10px auto;
}
.header-container .th:last-child{
  background-color: #343E68 !important;
  width: 80px !important;
}
.header-container .th:last-child:hover, .tab-container:hover{
  cursor: pointer;
}
.td.text-center.row-td:last-child{
  width:80px !important;
}
.full-td.text-center.row-td:last-child{
  width:90px !important;
}

.main-card-body{
  padding: 0px !important;
  background-color: #25305D !important;
}
.sample-main-card-body{
  padding: 0px !important;
  background-color: #111928 !important;
}

.data-row-group{
  background-color: #1F283D !important;
  padding-top:10px !important;
}

.full-data-row-group{
  background-color: #1F283D !important;
  padding-top:10px !important;
}

.main-table-body{
  margin-bottom:0px !important;
}

.visibleContainer{
  display:block
}
.invisibleContainer{
  display:none;
}

.tickerColumn:hover{
  cursor: pointer;
  transform: scale(1.2);
}

.main-card-outer .react-datepicker-wrapper{
  padding-top:8px !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
.tab-content .card-body{
  background-color: #20293C !important;
}

.right-dashboard-content{
  max-height: 98vh !important;;
}

.data-grid-block{
  position:inherit;
}
.header{
  position:sticky !important;
  top:0 !important;
  z-index: 100 !important;
  background-color: #25305D !important;
}

/* #endregion Dashboard */

/* #region Account Page */

.termsText{
  color: white !important;
}
.termsModal{
  height: 50%;
  width: 95%;
  overflow:scroll;
}
.accordionToggle:focus{
  outline: none!important;
}

.passwordSavedAlertSuccess{
  width: 520px !important;
  color: white !important;
  font-weight: bold  !important;
  margin-top: 14px !important;
  background-color: seagreen !important;
}

.passwordSavedAlertError{
  width: 520px !important;
  color: white !important;
  font-weight: bold  !important;
  margin-top: 14px !important;
  background-color: crimson !important;
}

.accountTextfield .MuiFormLabel-root{
  line-height:1.4em;
}

/* #endregion Account Page */

/* #region Flowcast Page */

.upperSnapshotCard{
  flex-grow: 0;
  width: 83.4%;
  /* background-color: #1A2344; */
}
.upperSnapshotCard .react-datepicker-wrapper{
  margin-top: auto !important;
  margin-left: 5px !important;
}
.upperSnapshotCard .react-datepicker-wrapper:hover{
  cursor: pointer;
}

.flowcastHeader{
  margin: unset;
  margin-top:10px;
  font-family: "League Spartan";
  font-weight: bolder;
}
.flowcastHeaderLine{
  border-top: 1px solid white !important;
  margin-top:5px !important;
}

/* #endregion Flowcast Page */

/* #region Sidebar */
.side-bar{
  z-index: 2;
  padding:10px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    transition: none 0s ease 0s;
    will-change: transform;
    overflow-y: auto;
    background: rgb(26, 35, 68);
    width: 65px;
    left: 0px;
    transform: translateX(0%);
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 4px;
}
.side-bar-icon{
  opacity:unset !important;
  width: 35px !important;
  height: 35px !important; 
  display: block !important; 
  margin-left: auto !important;
  margin-right: auto !important;
}

.icon-active{
  fill: #28ABDD !important;
}
.icon-inactive{
  fill: white !important;
}
.side-bar-icon:hover, .account-icon:hover{
  opacity: 0.8 !important;
}
.account-icon{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom:10px;
  text-align: center;
}
.audio-icon{
  opacity:unset !important;
  position: absolute !important;
  margin-left: auto !important;
  margin-right: auto !important;
  left: 0 !important;
  right: 0 !important;
  width:35px !important;
  height:35px !important;
  bottom:70px !important;
  text-align: center !important;
}
.audio-icon:focus{
  outline: none !important;
}
.tab-content-header{
  border-bottom: 1px solid white !important;
}
.tab-container {
  display: flex;
}
.tab-container > div {
  flex: 1; /*grow*/
  text-align: center;
}
.selected-tab{
  border-bottom:3px solid white !important;
}

.accountNavPopover{
  padding: 6px !important; 
  width: 160px !important; 
  background-color: 'white' !important;

}
.accountNavPopover a{
  color: black !important;
  font-weight:bold !important;
}
/* #endregion Sidebar */

/* #region Account Page */

.accountOuter{
  top: 235px;
  left: 249px;
  width: 60%;
  min-height: 600px;
  background-color: #1F283D !important;
  border-radius: 28px;
  padding: 50px;
  margin-top:80px;
  color: white !important;
}
.avatarSection{
  background: #3A4DBC2B 0% 0% no-repeat padding-box;
  border: 3px solid #3651B9;
  width: 300px;
  height: 400px;
  border-radius: 28px;
  text-align: center;
  padding:20px !important;
  display:flex;
  align-content:center;
}
.avatarSectionContainer{
  margin:auto !important;
}
.MuiAvatar-circle{
  width:150px !important;
  height:150px !important;
}
.MuiAvatar-circle img{
  border: 3px solid #DDDDDD;
  border-radius: 50%;
}
.detailsSection{
  width: 60%;
  margin-left: auto !important;
  overflow: scroll;
  max-height: 600px;
}
.accountAccordion{
  background-color: inherit !important;
  box-shadow: unset !important;
  border-bottom:1px solid #646464 !important;
}
.MuiTypography-body1{
  color:white !important;
  font-weight: bold !important;
}
.accountAccordion  .MuiSvgIcon-root{
  color: white !important;
}
.accountTextfield{
  width: 250px;
  height: 56px;
  opacity: 1;
}
.accountTextfield label, .accountTextfield input{
  color: white !important;
}
.accountTextfield input{
  border: 2px solid #FFFFFF !important;
  border-radius: 5px;
}
.accountTextfield input:hover, .accountTextfield input:focus, .accountTextfield input:active{
  text-decoration: none !important;
}
.accountTextfield input:focus{
  border: 3px solid #FFFFFF !important;
  
}
.termsAccordion .MuiCollapse-entered, .termsAccordion .MuiCollapse-container{
  /* max-height:150px; */
  overflow: scroll !important;
  color: whtie !important;
}
.accountSaveButton{
  width: 107px !important;
  height: 48px !important;
  background-color: #28ABDD !important;
  border-radius: 4px !important;
  color:white !important;
  font: normal normal medium 21px/24px Roboto !important;
  letter-spacing: 1.87px !important; 
}
.detailsSection .accountTextfield{
  margin-right:20px !important;
}
.detailsSection input, .detailsSection .accountTextfield{
  width:200px !important;
}
p.termsText{
  font-weight:normal !important;
  font-size: 12px !important;
}
/*#endregion Account Page */

/* #region Earnings Page */

.earningsItem{
  height:fit-content;
  margin:15px;
  color: white !important;
}

.epsRevenueTable{
  border: 1px solid white;
  width:100%;
}

.epsRevenueTable td{
  border: 1px solid white;
  text-align: center;
  width:33%;
}

.epsRevenueHeaderTableHeader{
  font-size: 12px;
}

.epsRevenueTableValue{
  font-weight:bold;
  min-height: 21px;
}

.earnings-header-container .th:last-child{
  background-color: unset !important;
}

/* #endregion Earnings Page */

/* #region Screen Size Adjustments */

@media (max-width:991px) {
  .navbarIcon{
    visibility: hidden !important;
  }
  .nav-block{
    position: fixed !important;
  }
  .below-nav-block{
    margin-top: 70px !important;
  }
  .navbar.bg-dark{
    background-color: #1A2344 !important;
  }
  .data-grid-block{
    position:absolute;
  }
  .full-main-card-body{
    padding:0 !important;
  }
}

/* Fix snapshot header width for smaller screen sizes */
@media (max-width:961px){
  .upperSnapshotCard{
    flex-grow:0;
    width:91.666667%;
  }
}

/* Tablet portrait mode */
@media (max-width:926px) {
  .allMiddleContent{
    overflow:scroll;
  }
  .earningsCardTableDiv .allMiddleContent{
    overflow:unset !important;
  }
  .annual{
    margin-left:0px !important;
  }
  .navbarIcon{
    visibility: hidden !important;
  }

  /* Upper 4 boxes */
  .upper-items{
    width: 50% !important
  }

  /* Watchlist Icon */
  .watchlist-button{
    margin-left:15px !important;
  }
  .watchlist-button>span{
    position:absolute;
  }
  .th{
    font-size:12px !important;
  }
  .row-td{
    white-space: nowrap;
    width:100px !important;
  }
  .full-row-td{
    white-space: nowrap;
    width: 90px !important;
  }
  .header-container{
    display:inline-flex !important;
    overflow:auto !important;
    width: 100vw !important;
  }
  .full-header-container{
    display:inline-flex !important;
  }
  .full-main-card-body{
    overflow-x:scroll !important;
    width:fit-content !important;
  }

  .main-data-row{
    display:inline-flex !important;
    width: 100vw !important;
  }
  .earningsCardTableDiv .main-data-row, .earningsCardTableDiv .header-container{
    width:unset !important;
  }
.earningsCardTableDiv .data-row-group{
  height:unset !important;
}
.earningsCardTableDiv .fixed-size-list{
  background-color: #1F283D !important;
}

  .data-grid-block{
    width: 1380px !important;
  }
  .header{
    /* width: 100vw !important; */
    width:fit-content !important;
  }

  .data-grid-block{
    /* width: 1380px !important; */
    width: 100% !important;
  }

  .data-grid-block-earnings{
    width: 783px !important;
    height: 162px !important;
  }
  .header-earnings{
    width: 783px !important;
  }
  
  .main-card-outer{
    min-width: unset !important;
    margin-bottom:10px !important;
  }
  .main-table-body{
    display: inline-grid;
  }
  .full-main-table-body{
    display: inline-grid;
  }
  .full-fixed-size-list{
    height: 90vh !important;
  }

  .mobile-watchlist{
    margin: 0px 8px 10px 8px !important;
    height:fit-content !important;
  }
.mobile-watchilst-header{
    padding-top:0px !important;
  }
  .mobile-watchilst-body{
    padding: 5px 15px !important;
  }

  .grid-header-left{
    margin-left:auto !important;
    margin-right:auto !important;
  }
  .news-block{
    height:fit-content !important;
  }
  .header-container .th select {
    width: 70px !important;
  }
  .dateContainer, .searchContainer{
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .avatarSection {
    width:90% !important;
    height: unset !important;
    padding: 15px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .accountOuter {
    width: unset !important;
    height: unset !important;
    padding: 20px 10px !important;
  }
  .detailsSection{
    width: unset !important;
    height: unset !important;
    max-height:unset;
    padding: 15px !important;
  }
  .manageHeader{
    width:fit-content !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .accountTextfield{
    width: 200px !important;
  }
  .accountPageSizing{
    height:unset !important;
    min-height: unset !important;
    overflow-x: unset !important;
  }
  .data-row-outer-div{
    width:fit-content !important;
  }
  .grid-header-switch-span{
    display:inline-block;
  }
  .card-header{
    text-align: start !important;
  }
}

/* Phone portrait mode */
@media (max-width:430px) {
  .navbarIcon{
    visibility: hidden !important;
  }

  /* Upper 4 boxes */
  .upper-items{
    width: 50% !important;
    margin-bottom: -11px !important;
  }

  .main-card-outer{
    min-width: unset !important;
  }

  h3.card-title{
    font-size:18px !important;
  }
}

@media(max-width:1287px){
  .accountPasswordDetails p{
    text-align: center;
  }
}


/* FlowCast table scroll for screen size adjustments */
@media(max-width:1245px){
  .flowCastOuter{
    overflow-x: scroll !important;
  }
  .flowCastInner{
    width:fit-content !important;
  }
  .flowCastInner .th, .flowCastInner .td{
    width: 110px !important;
  }
}
@media(max-width:600px){
  .watchlist-iframe{
    width:100% !important;
  }
  .etfFlowcast{
    float:unset !important;
    text-align: unset !important;
    display: block !important;
    white-space: nowrap !important;
    max-width: unset !important;
  }
  .accountOuter{
    background-color: transparent !important;
    box-shadow: unset !important;
  }

}

@media(max-width:1400px){
  .accountOuter{
    padding:15px !important;
  }
}
@media(max-width:1300px){
  .accountOuter{
    height: fit-content !important;
    padding:20px !important;
  }
  .avatarSection{
    display:block !important;
    margin-left:auto !important;
    margin-right: auto !important;
  }
  .detailsSection{
    display: block !important;
    margin-left:auto !important;
    margin-right: auto !important;
  }
}




.th.full-row-td{
  text-align: center !important;
}

/* #endregion Screen Size Adjustments */



.wishlistProgressBar{
  position:absolute !important;
  left:0 !important;
  width:100% !important;
  height:30px !important;
  border-radius: 5px !important;
  box-shadow: unset !important;
  z-index: -1 !important;
}

.wishlistProgressBarBearish{
  background-color: rgb(248, 114, 64) !important;
}
.wishlistProgressBarBullish{
  background-color: rgb(12, 209, 125) !important;
}
.wishlistProgressBarNeutral{
  background-color: rgb(131, 131, 131) !important;
}

.main-pricing-div{
  padding-top:25px;
  margin-left:auto !important;
  margin-right:auto !important;
  text-align: center;
  font-weight: bold;
  
}
.pricing-card{
  color: white !important;
  display:inline-block;
  border-radius:18px !important;
  box-shadow: inset 0 0 0 2px #2b333d;
  margin: '5px'
}
.pricing-card hr{
  width:95%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.pricing-list{
  margin-top: 10px;
  margin-bottom: 20px;
  width: '75%';
  margin-left:auto;
  margin-right:auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  list-style-type: none;
}
.list-item-with-check {
  padding-top: 2px;
  padding-bottom: 2px;
  background-image: url(https://uploads-ssl.webflow.com/5ff0920b559a42afada73f05/5ff0920b650740d9d5625979_check-circle.svg);
  background-position: 3px 50%;
  background-size: auto;
  background-repeat: no-repeat;
  color: #fff;
}
.button-second{
  width: 80%;
  padding-top: 16px;
  padding-bottom: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  background-color: rgba(58, 218, 145, 0);
  color: #fff;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 30px;
  margin-bottom:20px;
}
.button-second:hover{
  color:white;
  background-color: #13cc78;
  border: 1px solid #13cc78;
}
.button-second:visited, .button-second:active{
  color:white;
}
.annual{
  background-image: linear-gradient(132deg, #f85d7f, #6b81fa);
}

.cb-button .cb-button__primary{
  background-color: rgb(40, 171, 221) !important;
}

.stockAlertsTable {
  background-color: rgb(31, 40, 61) !important;
  height:100% !important;
}
.stockAlertsTable tbody{
  display: block;
  overflow-y: scroll;
  max-height: 86vh;
}

.stockAlertsTable thead, .stockAlertsTable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* @media(max-width:899px){
  .stockAlertsTable{
    height:unset !important;
  }
  .stockNewsTable{
    padding-top:0px !important;
  }
  .stockNewsHeader{
    margin-bottom:unset !important;
    margin-top:unset !important;
  }
  .stockAlertsTable tbody{
    max-height: 30vh !important;
  }
  .stockAlertsOuter{
    height: unset !important;
  }
  .stockNewsHeader{
    padding-top:0;
    padding-bottom:0;
  }
  .stockNewsOuter .react-datepicker-wrapper{
    display: none;
  }
} */

@media(max-width:1080px){
  .stockAlertsTable  td:last-child{
    width: 25% !important;
  }
}

@media(max-width:900px){
  .stockAlertsTable td:last-child{
    width:17% !important;
  }
  .stockAlertsGrid:first-of-type{
    height:40% !important;
  }
  .stockAlertsGrid:last-of-type{
    height:47% !important;
  }
  .stockNewsHeader{
    margin-bottom:0 !important;
  }
  .stockAlertsGridNews .react-datepicker-wrapper{
    display:none;
  }
  .stockAlertsTable tbody{
    max-height:33vh !important;
  }
  .stockNewsTableInner tbody{
    max-height:41vh !important;
  }
}

@media(max-width:600px){
  .stockAlertsTable tbody{
    max-height:31vh !important;
  }
  .stockNewsTableInner tbody{
    max-height:41vh !important;
  }
}